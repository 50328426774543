//import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

document.body.style.backgroundColor = "#faffff";



const images = [
  {
    original: 'https://us.solence.net/data/img/IMG-5.jpg',
    thumbnail: 'https://us.solence.net/data/img/IMG-5.jpg',
  },
  {
    original: 'https://us.solence.net/data/img/IMG-2.jpg',
    thumbnail: 'https://us.solence.net/data/img/IMG-2.jpg',
  },
  {
    original: 'https://us.solence.net/data/img/IMG-3.jpg',
    thumbnail: 'https://us.solence.net/data/img/IMG-3.jpg',
  },
];
 
class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}


const Card1 = () => {
 
  const calculateTime = () => {
    var diff = Math.abs(new Date() - new Date('2017/05/07 15:30:00'));
    var seconds = Math.floor(diff / 1000),
    minutes = Math.floor(seconds / 60),
    hours   = Math.floor(minutes / 60),
    days    = Math.floor(hours / 24),
    months  = Math.floor(days / 30.5),
    years   = Math.floor(days / 365);
    let timeSoFar = {};
    
    seconds %= 60;
    minutes %= 60;
    hours %= 24;
    days %= 31;
    months %= 12;
    
      timeSoFar = {
        seconds,
        minutes,
        hours,
        days,
        months,
        years
      };
    
  
    return timeSoFar;
  };
  const [timeSoFar, setTimeSoFar] = useState(calculateTime());
  useEffect(() => {
    setTimeout(() => {
      setTimeSoFar(calculateTime());
    }, 1000)
  });
  return (
    <div className="shadow-sm p-3 mb-5 bg-white rounded" >
  <div className="card-body">
    <h6 className="card-subtitle mb-2 text-muted">Dating for:</h6>
    <h5 className="card-title">{timeSoFar.years} years {timeSoFar.months} months {timeSoFar.days} days<br/> {timeSoFar.hours} : {timeSoFar.minutes} : {timeSoFar.seconds} 
    </h5>  
  </div>
</div>
  );
};
class CardMovies extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = [];
    
    this.state = {
      value: false,
      setValue: false,
      movies: [],
      datas: [{"id":"9","title":"...........","crossed":"0"}],
    };
  }
  getData() {
    
    //fetch('http://localhost:82/datebe/')
    fetch('https://us.solence.net/data/')
      .then(response => response.json())
      .then(data => {
        this.setState({ datas: data })
        
      });
  }

   run() {
    /*
     // Create a "close" button and append it to each list item
      var myNodelist = document.getElementsByTagName("LI");
      var i;
      for (i = 0; i < myNodelist.length; i++) {
        var span = document.createElement("SPAN");
        var txt = document.createTextNode("\u00D7");
        span.className = "close";
        span.appendChild(txt);
        myNodelist[i].appendChild(span);
      }

      // Click on a close button to hide the current list item
      var close = document.getElementsByClassName("close");
      var i;
      for (i = 0; i < close.length; i++) {
        close[i].onclick = function() {
          fetch('http://localhost:82/datebe/index.php/movies/delete/', {
          method: 'DELETE',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify({id: '2'})
        })
        .then(res => res.text()) // OR res.json()
        .then(res => console.log(res))
          var div = this.parentElement;
          div.style.display = "none";
        }
      }*/

      // Add a "checked" symbol when clicking on a list item
      var list = document.querySelector('ul');
      list.addEventListener('click', function(ev) {
        if (ev.target.tagName === 'LI') {
          ev.target.classList.toggle('checked');
        }
      }, false);

      
  }

   newElement() {
    var close = document.getElementsByClassName("close");
    var li = document.createElement("li");
    var inputValue = document.getElementById("myInput").value;
    var t = document.createTextNode(inputValue);
    li.appendChild(t);
    if (inputValue === '') {
      alert("You must write something!");
    } else {
      document.getElementById("myUL").appendChild(li);
    }
    document.getElementById("myInput").value = "";
  
    var span = document.createElement("SPAN");
    var txt = document.createTextNode("\u00D7");
    span.className = "close";
    span.appendChild(txt);
    li.appendChild(span);
    var i;
    for (i = 0; i < close.length; i++) {
      close[i].onclick = function() { 
        alert("delete");
        // Click on a close button to hide the current list item
        var div = this.parentElement;
        div.style.display = "none";
       }
    }
    //fetch('http://localhost:82/datebe/index.php/movies/create/', {
      fetch('https://us.solence.net/data/index.php/movies/create/', {
          method: 'POST',
          mode: 'no-cors', // no-cors, *cors, same-origin
         // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: 'same-origin', // include, *same-origin, omit
          
          body: JSON.stringify({movie: inputValue}),
          headers: {'Accept': 'application/json',
          'Content-Type': 'application/json',},
        })
        .then(res => res.text()) // OR res.json()
        .then(res => console.log(res))
        .catch((error) => {
          console.error(error);
      });
  }
  removeElement(i){
    //alert("function: delete");
   /*  console.log(elem);
    console.log(elem.parentElement)
    console.log(elem.children) */
    //fetch('http://localhost:82/datebe/index.php/movies/delete/'+i, {
      fetch('https://us.solence.net/data/index.php/movies/delete/'+i, {
          method: 'DELETE',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify({id: '2'})
        })
        .then(res => res.text()) // OR res.json()
        .then(res => console.log(res))
    // Click on a close button to hide the current list item
      const node = this.myRef[i];
      node.style.display = "none";
  }
  //run();
  componentDidMount() {
    this.getData();
    this.run();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.datas !== this.state.datas) {
      console.log('changed.');
      this.run();
    }
  }
 
render(){
  //const movies = this.state.movies;
  var movies = this.state.datas;
  //movies = Array.from(movies);
  return(
    <>
      <h2>Movies</h2>
      <ul id="myUL">
        {movies.map((movie) => (<li key={movie.id} ref={ref => (this.myRef[movie.id] = ref)}>{movie.title}<span onClick={()=>{this.removeElement(movie.id)}} class="close">×</span></li>))} 
        {console.log(movies) }
        
      </ul>
      <div id="myDIV" className="header">
      <input type="text" id="myInput" placeholder="Movie name..."/>
      <span onClick={()=> this.newElement()} className="addBtn">Add</span>
    </div>
  </>
  );
}
}
const Row1 = () => {
  return (
    <div className="row">
      <div className="col-sm">
      <Card1 />
      </div>
      <div className="col-sm">
      <div className="shadow-sm p-3 mb-5 bg-white rounded" >
      <h2>Us</h2>
      <MyGallery />
      </div>
      
      </div>
      <div className="col-sm">
      
      </div>
    </div>
  );
};
const Row2 = () => {
  return (
    <div className="row">
      <div className="col-sm-4">
      <div className="shadow-sm p-3 mb-5 bg-white rounded" >
        
        <CardMovies />
      </div>
      </div>
      <div className="col-sm">
      
      </div>
    </div>
  );
};


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      setValue: false,
    };
  }
  
  render() {
    return (
      <div className="container">
      <Row1/>
      <Row2/>
    </div>
      
    );
  }
}


ReactDOM.render(
  <App />,
  document.getElementById('root')
);